import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"

import PageWrapper from "../../../components/PageWrapper"

import { InviteFormContainer } from "./containers"

import { inviteNewTenant as apiInviteNewTenant } from "../../../services/tenantsApiV2"

function InviteNewTenantPage({ location, tenancyId }) {
  const [inviteDetails, setInviteDetails] = React.useState(
    Array(1).fill({
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      isMissingInformation: false,
    })
  )

  const [submitting, setSubmitting] = React.useState(false)
  const [successfulResponse, setSuccessfulResponse] = React.useState(false)
  const [errors, setErrors] = React.useState([""])

  const inviteNewTenant = async () => {
    setErrors([])

    try {
      const tenantDetails = inviteDetails[0]

      setSubmitting(true)

      const data = await apiInviteNewTenant({
        firstName: tenantDetails.firstName,
        lastName: tenantDetails.lastName,
        email: tenantDetails.email,
        phone: tenantDetails.number,
        newTenantAuthString: tenancyId,
      })

      if (data.success) {
        setSuccessfulResponse(true)
      } else if (data.errors) {
        setErrors([data.errors[0].reason])
      } else {
        setErrors(["An error occurred trying to invite tenant"])
      }
      setSubmitting(false)
    } catch (e) {
      setSubmitting(false)
      setErrors([e.message])
    }
  }

  return (
    <PageWrapper
      location={location}
      quoteViewMode={true}
      backgroundColor="#FAFAFA"
    >
      <InviteFormContainer
        inviteDetails={inviteDetails}
        setInviteDetails={e => setInviteDetails(e)}
        groupLeadEmail={""}
        groupLeadPhoneNumber={""}
        buttonOnClick={inviteNewTenant}
        buttonSubmitting={submitting}
        errors={errors}
        successfulResponse={successfulResponse}
      />
    </PageWrapper>
  )
}

export default InviteNewTenantPage
