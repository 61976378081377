import React, { useState } from "react";

const CopyText = ({ label, text }) => {
  const [copied, setCopied] = useState(false);

  if (!text) return null; // Don't render if there's no text to copy

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <>
      <br />
      {label}:{" "}
      <span
        style={{ cursor: "pointer", color: "#FAFAFA", textDecoration: "underline" }}
        onClick={handleCopy}
      >
        {text}
      </span>
      {copied && <span style={{ marginLeft: "5px", color: "green" }}>Copied to clipboard ✅</span>}
    </>
  );
};

export default CopyText;