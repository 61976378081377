import React, { useEffect, useState, useContext } from "react"
import clsx from "clsx"
import { navigate } from "gatsby"
import { DateTime } from "luxon"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"

import {
    GlobalDispatchContext,
} from "../../../context/GlobalContextProvider"

import PageWrapper from "../../../components/PageWrapper"

import SuccessImage from "../../../assets/meter-reading-success.png"

import {
    SignUpInformationContainer,
    SignUpChoiceContainer,
    PersonalFormContainer,
    InviteFormContainer,
    TenancyFormContainer,
    SummaryContainer,
} from "./containers"
//import CardSection from "./containers/CardSection"
//import TopBarContainer from "./containers/TopBarContainer"
import PaymentResponseContainer from "./containers/PaymentResponseContainer"
import {
    generatePhoenixSession,
    processPayment,
} from "../../../services/quotingServiceApi"

import {
    Button,
    Errors,
    FormLine,
    SubTitleText,
    Center,
    Centered1200Wrapper,
    TextWithSubtext,
    FormLabels,
    PageTitle,
} from "../components"
import InputSection from "../components/InputSection"

import {
    useGetGroup,
    useGetLead,
    useAgreedToTerms,
    useSelectedQueueJump,
} from "../services/group"

import { getMatchingQuotingToolVersionById } from "../services/utils"

import Wrapper from "../components/Wrapper"

const useStyles = makeStyles({
    checkbox: {
        color: "#000 !important",
        paddingLeft: "0px",
    },
    checkboxLabel: {
        fontSize: "15px",
        fontFamily: "tenon, sans-serif",
        color: "#000 !important",
        "& a": {
            color: "#000 !important",
        },
    },
})

function PaymentPage({ location, groupId, toolId, leadId, checkoutId, status, siteData }) {
    const[isSucessfulCheckout, setSucessfulCheckout] = useState(status === "success")
    const[processed, setProcessed] = useState(false)
    const dispatch = useContext(GlobalDispatchContext)
    const frontEndUrl = window.location.origin
    const groupQuery = useGetGroup(groupId)
    const leadQuery = useGetLead(leadId)

    const queryParameters = new URLSearchParams(location.search)
    const stripeCustomerId = queryParameters.get("stripeCustomerId")
    const tenantId = queryParameters.get("tenantId")

    useEffect(() => {
        if (!processed) {
            processPayment(checkoutId, stripeCustomerId, tenantId)
                .then(response => {
                    setProcessed(true);
                })
                .catch(error => {
                });

             // Ensure it runs only once
        }
    }, []); // 

    const group =
        groupQuery.data && groupQuery.data.value && groupQuery.data.value.group
    const latestPrices =
        groupQuery.data && groupQuery.data.value && groupQuery.data.value.services
    const lead = leadQuery.data && leadQuery.data.value

    let costPerMonth = 0
    let proRataSignUpFeeToday = 0

    if (groupQuery.data && groupQuery.data.value && groupQuery.data.value) {
        costPerMonth = groupQuery.data.value.costPerMonth
        proRataSignUpFeeToday = groupQuery.data.value.proRataSignUpFeeToday
    }

    let matchingQuotingToolVersion =
        groupQuery.data &&
        groupQuery.data.value &&
        getMatchingQuotingToolVersionById(
            siteData,
            groupQuery.data.value.group.quote.quotingToolVersionId
        )

    useEffect(() => {
        if (matchingQuotingToolVersion) {
            var head = document.head
            var link = document.createElement("link")

            link.type = "text/css"
            link.rel = "stylesheet"
            link.href = matchingQuotingToolVersion.customCSSURL

            head.appendChild(link)


            const isHomeownerOrRenter =
                matchingQuotingToolVersion.type.includes("Homeowner") ||
                matchingQuotingToolVersion.type.includes("Renter") ||
                matchingQuotingToolVersion.type.includes("Residential")

            if (isHomeownerOrRenter) {
                dispatch({ type: "setPriceModeView", priceModeView: "month" })
            }

            return () => {
                head.removeChild(link)
            }
        }
    }, [matchingQuotingToolVersion])

    if (
        groupQuery.data &&
        groupQuery.data.errors &&
        groupQuery.data.errors.find(error => error === "Quote prices have expired")
    ) {
        return (
            <Wrapper
                location={location}
                matchingQuotingToolVersion={matchingQuotingToolVersion}
                toolId={toolId}
                quotePricesExpired={true}
                showTrustpilotWidget={true}
            ></Wrapper>
        )
    }

    if (
        !matchingQuotingToolVersion ||
        groupQuery.isLoading ||
        leadQuery.isLoading
    ) {
        return (
            <PageWrapper
                location={location}
                quoteViewMode={true}
                backgroundColor="#FAFAFA"
                hideHeaderBorder={true}
                logoImageUrl={""}
            >
                <Center>
                    <CircularProgress />
                </Center>
            </PageWrapper>
        )
    }

    const isHomeownerOrRenter =
        matchingQuotingToolVersion.type.includes("Homeowner") ||
        matchingQuotingToolVersion.type.includes("Renter") ||
        matchingQuotingToolVersion.type.includes("Residential")

    if (latestPrices.services) {
        let broadbandService = latestPrices.services.find(
            service =>
                service.category === "Broadband" &&
                service.displayInQuote === "1. Package Section"
        )

        if (broadbandService != null) {
            let packageDetails = JSON.parse(group.quote.packageDetails)

            if (packageDetails != null) {
                let selectedBroadbandService = packageDetails.find(
                    selectedService => selectedService.serviceId === broadbandService.id
                )
            }
        }
    }

    const signedUpScreen = (
        <Wrapper
            location={location}
            matchingQuotingToolVersion={matchingQuotingToolVersion}
            group={group}
            toolId={toolId}
            quoteId={group.quote.id}
            quoteSummaryData={{
                id:group.quote.id,
                quoteUrl: group.signUpUrl,
                isInternalTool: group.isInternalSalesTool,
                services: groupQuery.data.value.services.services,
                tenancyDetails: group.quote.tenancyDetails,
                packageDetails: group.quote.packageDetails,
            }}
            showTrustpilotWidget={true}
            isHomeownerOrRenter={isHomeownerOrRenter}
        >
            <Centered1200Wrapper>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <img
                        alt="Success"
                        style={{ height: 60, width: 60 }}
                        src={SuccessImage}
                    />
                </div>

                <div style={{ color: "#000", padding: 20 }}>
                    <div
                        style={{
                            padding: "10px 0px",
                            fontSize: "24px",
                            textAlign: "center",
                        }}
                    >
                        You're all set!
                    </div>
                    <Button
                        fullWidth
                        className={clsx(
                            "SignUp-Step-06-Complete",
                            "quotingTool-submitButton"
                        )}
                        onClick={() =>
                            generatePhoenixSession(lead.id).then(r => {
                                                                if (r.isSuccess) {
                                                                    window.location.href = r.value;
                                                                }
                                                                else {
                                                                    //setSubmitting(false)
                                                                    //setErrors(r.error)
                                                                }
                                                            })
                        }
                    >
                        Return to overview
                    </Button>
                </div>
            </Centered1200Wrapper>
        </Wrapper>
    )

    return (
    
        <Wrapper
            location={location}
            matchingQuotingToolVersion={matchingQuotingToolVersion}
            group={group}
            toolId={toolId}
            quoteId={group.quote.id}
            quoteSummaryData={{
                id:group.id,
                quoteUrl: group.signUpUrl,
                isInternalTool: group.isInternalSalesTool,
                services: groupQuery.data.value.services.services,
                tenancyDetails: group.quote.tenancyDetails,
                packageDetails: group.quote.packageDetails,
            }}
            showTrustpilotWidget={true}
            isHomeownerOrRenter={isHomeownerOrRenter}
        >
            <PaymentResponseContainer
                group={group}
                lead={lead}
                costPerMonth={costPerMonth}
                proRataSignUpFeeToday={proRataSignUpFeeToday}
                //processPayment={processCheckout}
                success={isSucessfulCheckout}
                matchingQuotingToolVersion={matchingQuotingToolVersion}
            stripeCustomerId={stripeCustomerId}
            tenantId={tenantId}
            />
        </Wrapper>
    )
}
export default PaymentPage
