import React from "react"

function formatNumber(number) {
    // Convert the number to a string with up to 2 decimal places
    let formatted = number.toFixed(2);
    // Remove trailing zeros and the decimal point if there are no decimal places
    formatted = formatted.replace(/(\.0*|(\.\d+?)0*)$/, "$2");
    return parseFloat(formatted);
}

const EnergyTooltipInfo = ({ s, tenancyDetails, quotingToolMultipliers = [] }) => {
    return (
        <div >
            {s.products
                .filter(product => product.allowance > 0)
                .sort((a, b) => {
                    if (a.category === "Electric" && b.category === "Gas") {
                        return -1;
                    } else if (a.category === "Gas" && b.category === "Electric") {
                        return 1;
                    }
                    return 0;
                })
                .map((product) => {
                    const tenancyDetailsJson = JSON.parse(tenancyDetails.packageDetails)
                    const energyService = tenancyDetailsJson.find(service => service.category === 'Energy');
                    const allowanceModifier = energyService ? energyService.allowance : 0;

                    let allowance = product.allowance;

                    if (!!allowanceModifier)
                        allowance = allowance * (1 + (allowanceModifier / 100))
                    return (
                        <div style={{ marginTop: 10 }}>
                            <div>
                                {product.category} Allowance: <b>
                                    {formatNumber(allowance)}
                                    {product.productAllowanceUnits}
                                </b>
                            </div>
                            {product.sellRate1 > 0 && !product.sellRate2 && (
                                <div>
                                    Unit Rate: <b style={{ fontWeight: 'bold' }}>
                                        {(product.sellRate1 * 100).toFixed(2)}p per kWh
                                    </b>
                                    <br />Standing Charge: <b style={{ fontWeight: 'bold' }}>
                                        {(product.sellSC * 100).toFixed(2)}p a day
                                    </b>
                                </div>
                            )}
                            {product.sellRate1 > 0 && product.sellRate2 > 0 && (
                                <div>
                                    Day Rate: <b style={{ fontWeight: 'bold' }}>
                                        {(product.sellRate1 * 100).toFixed(2)}p per kWh
                                    </b>
                                    <br />Night Rate: <b style={{ fontWeight: 'bold' }}>
                                        {(product.sellRate2 * 100).toFixed(2)}p per kWh
                                    </b>
                                    <br />Standing Charge: <b style={{ fontWeight: 'bold' }}>
                                        {(product.sellSC * 100).toFixed(2)}p a day
                                    </b>
                                </div>
                            )}
                        </div>
                    );
                })
            }
        </div>
    );
};
export default EnergyTooltipInfo