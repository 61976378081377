import React, { useEffect, useState, useCallback } from "react"
import { navigate } from "gatsby"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

import {
    Button,
    Errors,
    Centered1200Wrapper,
    PageTitle,
} from "../../components"
import {
    generatePhoenixSession,
} from "../../../../services/quotingServiceApi"
import { CognitoSync } from "aws-sdk"

const useStyles = makeStyles({
    checkbox: {
        color: "#000 !important",
        paddingLeft: "0px",
    },
    checkboxLabel: {
        fontSize: "15px",
        fontFamily: "tenon, sans-serif",
        color: "#000 !important",
        "& a": {
            color: "#000 !important",
        },
    },
})

function PaymentResponseContainer({
    lead,
    group,
    matchingQuotingToolVersion,
    success })
    {
    const [paymentSucess, setPaymentSucess] = React.useState(success)
    const [isPrimaryLead, setPrimaryLead] = React.useState(lead.id === group.primaryLeadId)
    const [isOnlyBillPayer, setOnlyBillPayer] = React.useState(group.numberOfBillPayers === 1)
    const [submitButton, setSubmitButton] =  React.useState("Go To Overview")

    const useInvite = isPrimaryLead && group.otherLeads.length > 0;

    const leadId = lead.id;
    const groupId = group.id;
    const toolId = group.quote.toolId;

    React.useEffect(() => {
        document.title = "Bunch - Payment Details"
    }, [])

    const [submitting, setSubmitting] = React.useState(false)
    const [errors, setErrors] = React.useState([""])

    const [paymentName, setPaymentName] = React.useState("")
    const classes = useStyles()

    const currentDate = new Date()
    const startDate = new Date(group.tenancyStartDate)

    const chargeNow =
        currentDate > startDate || currentDate.getMonth() == startDate.getMonth()

    const handleContinue = useCallback(() => {
        if (paymentSucess) {
            if (isOnlyBillPayer) {
                navigate(`/tenants/app/signup/complete/${groupId}/success`)
            } else if (isPrimaryLead && !isOnlyBillPayer) {
                navigate(`/tenants/app/${toolId}/signup/complete/${groupId}/${leadId}?tab=invite`)
            } else if (!isPrimaryLead) {
                navigate(`/tenants/app/signup/complete/${groupId}/success`)
            } 
        }
        else {
            navigate(`/tenants/app/${toolId}/signup/complete/${group.id}/${leadId}?tab=payment`)
        }
    }, [success, isOnlyBillPayer, isPrimaryLead, lead.id, group.id])

    return (
        <div style={{ minHeight: "calc(10up0vh - 384px)" }}>
            <Centered1200Wrapper>
                <div style={{
                    padding: "20px 20px 0px"
                }}>
                    <PageTitle
                        style={{ textAlign: "left" }}
                    >
                        <div className="quotingTool-pageTitle">
                            {paymentSucess ? "Payment Details Sucessfully Added" : "We were not able to add you payment details"}
                        </div>
                    </PageTitle>
                    {matchingQuotingToolVersion.paymentPageDescription && (
                        <div
                            style={{
                                fontWeight: "400",
                                color: "#000",
                                opacity: "0.54",
                                marginBottom: 10,
                            }}
                            className="quotingTool-pageDescription"
                        >
                            {matchingQuotingToolVersion.paymentPageDescription}
                        </div>
                    )}
                    <div
                        style={{
                            fontWeight: "400",
                            color: "#000",
                            opacity: "0.54",
                            marginBottom: 20,
                        }}
                        className="quotingTool-pageDescription"
                    >
                        {paymentSucess ? (
                            <div>
                                <div style={{ marginBottom: 10 }}>
                                    Your payment details have been added sucessfully.
                                </div>
                                <div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div style={{ marginBottom: 10 }}>
                                    We haven't been able to add your payment details. Please click Try Again to add your details. If you continue to have issues please contact us.
                                </div>
                                <div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div style={{ padding: "0 20px" }}>
                    <Errors style={{ marginBottom: 20 }}>
                        {errors && errors.map(e => <>{e}</>)}
                    </Errors>
                    {!success ?
                        <Button
                            className={clsx(
                                "SignUp-Step-05-PaymentDetails",
                                "quotingTool-submitButton"
                            )}
                            fullWidth
                            disabled={submitting}
                            showShine
                            onClick={handleContinue}>
                            {submitting ? "Redirecting" : "Retry checkout"}
                        </Button> :
                        <Button
                            className={clsx(
                                "SignUp-Step-05-PaymentDetails",
                                "quotingTool-submitButton"
                            )}
                            fullWidth
                            disabled={submitting}
                            showShine
                            onClick={handleContinue}
                        >
                            {paymentSucess ? (submitting ? "View Sumamry" : (useInvite ? "Continue" : "Go To Overview")) : (submitting ? "Try Again" : "Go To Checkout")}
                        </Button>}
                </div>
            </Centered1200Wrapper>
        </div>
    )
}

export default PaymentResponseContainer