import React from 'react';
import { Box, Typography, Paper, Chip, Button, Divider } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BookmarkIcon from '@mui/icons-material/Bookmark';

const QuoteReference = ({ quote }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  if (!quote.isInternalTool) return null;

  return (
    <Paper 
      elevation={2}
      sx={{ 
        width: '100%',
        p: 3, 
        mt: 4, 
        mb: 4,
        borderRadius: 2,
        borderLeft: '4px solid #AEAEFF',
        backgroundColor: '#08033D'  // Dark Blue Background
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ color: '#FAFAFA', display: 'flex', alignItems: 'center' }}>
        <BookmarkIcon sx={{ mr: 1, color: '#AEAEFF' }} />
        Quote Reference
      </Typography>
      
      <Divider sx={{ mb: 2, backgroundColor: '#AEAEFF' }} />
      
      <Typography variant="body1" sx={{ mb: 2, color: '#FAFAFA' }}>
        Your unique quote data. Store this information to access and reference your quote later!
      </Typography>
      
      {quote.id && (
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Chip 
            label="Quote ID" 
            sx={{ 
              mr: 2, 
              width: '25%',
              mb: { xs: 1, sm: 0 }, 
              backgroundColor: '#AEAEFF', 
              color: '#08033D' 
            }} 
          />
          <Typography 
            variant="body2" 
            component="span" 
            sx={{ 
              fontFamily: 'monospace', 
              width: '30%',
              backgroundColor: 'rgba(250, 250, 250, 0.15)', 
              p: 1, 
              borderRadius: 1,
              flexGrow: 1,
              color: '#FAFAFA'
            }}
          >
            {quote.id}
          </Typography>
          <Button 
            startIcon={<ContentCopyIcon />} 
            size="small" 
            onClick={() => copyToClipboard(quote.id)}
            sx={{ ml: 2, color: '#AEAEFF' }}
          >
            Copy
          </Button>
        </Box>
      )}
      
      {quote.url && (
        <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <Chip 
            label="Quote Link" 
            sx={{ 
              mr: 2, 
              width: '25%',
              mb: { xs: 1, sm: 0 }, 
              backgroundColor: '#AEAEFF', 
              color: '#08033D' 
            }} 
          />
          <Typography 
            variant="body2" 
            component="span" 
            sx={{ 
              fontFamily: 'monospace',
              width: '45%',
              backgroundColor: 'rgba(250, 250, 250, 0.15)', 
              p: 1, 
              borderRadius: 1,
              flexGrow: 1,
              wordBreak: 'break-all',
              color: '#FAFAFA'
            }}
          >
            {quote.url}
          </Typography>
          <Button 
            startIcon={<ContentCopyIcon sx={{ color: '#AEAEFF' }}/>} 
            size="small" 
            onClick={() => copyToClipboard(quote.url)}
            sx={{ ml: 2, color: '#AEAEFF' }}
          >
            Copy
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default QuoteReference;
